import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, styled } from '@onehope/design-system-v2';

// Components and Helper Functions
import WineBundleOption from './BundleOption';
import Slider from './Slider';
import BULK_PRODUCTS_V2_QUERY from '../../queries/BulkProductsV2';
import GET_WC_DISCOUNT_CONFIG from '../../queries/DiscountConfigWC';
import { WineClub_DiscountConfigWCQuery } from '../../queries/generatedTypes/WineClub_DiscountConfigWCQuery';
import {
  WineClub_BulkProductsV2Query,
  WineClub_BulkProductsV2QueryVariables,
} from '../../queries/generatedTypes/WineClub_BulkProductsV2Query';

const GridFlex = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 12px; */
`;

type RecommendedBundlesProps = {
  useSliderView: boolean;
  setSelectedCuratedWinePackId: (packId?: string | null) => void;
  selectedCuratedWinePackId?: string;
  setLoading: (loading: boolean) => void;
};

const RecommendedBundles = ({
  useSliderView,
  setSelectedCuratedWinePackId,
  selectedCuratedWinePackId,
  setLoading,
}: RecommendedBundlesProps) => {
  const { data: wineClubProducts, loading: loadingWineClubProducts } = useQuery<
    WineClub_BulkProductsV2Query,
    WineClub_BulkProductsV2QueryVariables
  >(BULK_PRODUCTS_V2_QUERY, {
    variables: {
      productIds: [
        '64db4359-fac0-44af-870e-ca9d77bcdd7d',
        '120c8267-3383-42d8-a926-6295cb0f8485',
        'd74e1f87-681e-4dc5-8e55-0262fc130229',
      ],
    },
  });
  const { data: wineClubDiscounts, loading: loadingWineClubDiscounts } =
    useQuery<WineClub_DiscountConfigWCQuery>(GET_WC_DISCOUNT_CONFIG);

  useEffect(() => {
    const loading = loadingWineClubProducts || loadingWineClubDiscounts;
    setLoading(loading);
  }, [loadingWineClubProducts, loadingWineClubDiscounts]);

  const products = wineClubProducts?.viewer?.productsV2Bulk;
  const wineClubOptions = products?.edges?.map((props) => props?.node) || [];
  const discounts = wineClubDiscounts?.viewer?.v2?.discounts;

  if (loadingWineClubProducts || loadingWineClubDiscounts) return null;
  return (
    <>
      {useSliderView ? (
        <Slider
          products={wineClubOptions}
          discounts={discounts}
          setSelectedCuratedWinePackId={setSelectedCuratedWinePackId}
          selectedCuratedWinePackId={selectedCuratedWinePackId}
        />
      ) : (
        <GridFlex container spacing={2}>
          {wineClubOptions.map((product) => (
            <Grid item>
              {/* xs={12 / wineClubOptions.length} */}
              <WineBundleOption
                product={product}
                discounts={discounts}
                setSelected={setSelectedCuratedWinePackId}
                selected={selectedCuratedWinePackId === product?.productId}
              />
            </Grid>
          ))}
        </GridFlex>
      )}
    </>
  );
};
export default RecommendedBundles;
